import React from 'react'
import BaseComponent from './BaseComponent'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.props.history.push("/")
  }

  render () {

    return (
      <main>
        
      </main>
    )
  }
}
