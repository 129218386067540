import React from 'react'
import BaseComponent from './BaseComponent'
import Footer from './Footer'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { anim: false }
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  componentDidMount() {
  	document.addEventListener("keyup", this.onEnterPressed)
    setTimeout(() => { this.setState({ anim: true }) }, 600)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
  }

  render() {
    const page = this.props.data.start

    return (
      <main>
        <section className="start">
          <div className={ this.state.anim ? "start-box show" : "start-box" }>
            <div className="inputs">
              <div className="have-account"><p><a href={ this.props.lang === "pl" ? "https://panelbiznesowy.itaxi.pl" : "https://panelbiznesowy.itaxi.pl/en" }>{ page.login }</a></p></div>
              <h3>{ page.title }</h3>
              <div className="input">
                <p dangerouslySetInnerHTML={{ __html: page.description }}></p>
              </div>
              <div className="stores">
                { this.props.lang === "pl" ? <a href='https://play.google.com/store/apps/details?id=com.geckolab.eotaxi.passenger&hl=pl'><img alt='pobierz z Google Play' src='/assets/img/pl_badge_web_generic.png'/></a> : <a href='https://play.google.com/store/apps/details?id=com.geckolab.eotaxi.passenger&hl=en'><img alt='Get it on Google Play' src='/assets/img/en_badge_web_generic.png'/></a> } 
                { this.props.lang === "pl" ? <a href='https://apps.apple.com/pl/app/itaxi-aplikacja-taxi/id505936790?l=pl'><img alt='Pobierz w App Store' src='/assets/img/appStore_pl.svg'/></a> : <a href='https://apps.apple.com/pl/app/itaxi-aplikacja-taxi/id505936790?l=en'><img alt='Download on the App Store' src='/assets/img/appStore_en.svg'/></a> } 
              </div>
              <div className="input">
                <h4>{ page.businessAccount }</h4>
                <p dangerouslySetInnerHTML={{ __html: page.businessDescription }}></p>
                <a href={ this.props.lang === "pl" ? "https://panelbiznesowy.itaxi.pl" : "https://panelbiznesowy.itaxi.pl/en" } className="btn yellow" style={{ display: "block", marginTop: "30px" }}><span>{ page.goToPage }</span></a>
              </div>
            </div>
          </div>
        </section>
        <Footer data={ this.props.data }/>        
      </main>
    )
  }
}
