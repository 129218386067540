import React from 'react'
import BaseComponent from './BaseComponent'

export default class extends BaseComponent {

	constructor(props) {
		super(props)
    this.state = { anim: "", langList: false  }
    this._bind('toggleLangList', 'searchUpdated')
    this.KEYS_TO_FILTERS = ['name', 'email']
	}

  componentDidMount() {
    setTimeout(() => { this.setState({ anim: "anim" }) }, 400)

    window.addEventListener('click', () => {
      this.setState({ langList: false })
    })

    window.addEventListener('keydown', () => {
      this.setState({ langList: false })
    })
  }

  toggleLangList(e) {
    e.stopPropagation();
    if (this.state.langList) {
      this.setState({ langList: false })
    } else {
      this.setState({ langList: true })
    }
  }

  searchUpdated(term) {
    if (term === "") {
      term = this._nullTerm 
    }
    this.setState({searchTerm: term})
  }

  render() {

    return (
      <header className={ this.state.anim }>
        <div className="header-logo">
          <img className="logo" src="/assets/img/logo.png" alt="iTaxi logo"/>
          <p>{ this.props.data.header.title }</p>
        </div>
        <div className={ this.state.langList ? "lang-switch open " : "lang-switch " }>
          <div className="current-lang" onClick={ this.toggleLangList }>
            { this.props.lang }
          </div>
          <ul className="lang-list">
            <li><a href={ "https://aplikacje.itaxi.pl" }>PL</a></li>
            <li><a href={ "https://apps.itaxi.pl" }>EN</a></li>
          </ul>
        </div>
      </header>
    )
  }
}
