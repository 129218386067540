import React, { Component } from 'react'

export default class extends Component {

  render () {
  	const footer = this.props.data.footer

    return (
      <footer>
      	<div className="container">
      		<p>{ footer.info }</p>
	        <p className="copyrights">{ footer.copyrights }</p>
	    </div>
      </footer>
    )
  }
}
